// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-05ce622e]{font-family:\"Open Sans\"}.font-header[data-v-05ce622e]{font-family:\"Hero\"}.no-data-text[data-v-05ce622e]{padding-top:0!important;border:unset!important}.payment-container[data-v-05ce622e]{display:flex;width:100%;justify-content:space-between;align-items:baseline;grid-gap:.75rem;gap:.75rem}.payment-container span[data-v-05ce622e]{padding-right:0!important}.payment-container .icon-wrapper[data-v-05ce622e]{display:flex;align-items:center;grid-gap:.5rem;gap:.5rem}.payment-container .icon-wrapper .payment-icons[data-v-05ce622e]{margin-top:.25rem;font-size:1.75em!important;line-height:.04167em}.payment-container .has-payment-icon[data-v-05ce622e]{font-size:17px;margin-right:10px;margin-top:2px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
