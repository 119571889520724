// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-63fbde5e]{font-family:\"Open Sans\"}.font-header[data-v-63fbde5e]{font-family:\"Hero\"}.credit-warning[data-v-63fbde5e]{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:flex-start}.credit-warning img[data-v-63fbde5e]{width:50px;height:50px;margin-right:20px}.credit-warning .inner[data-v-63fbde5e]{flex:1;font-size:12px;line-height:18px}.credit-warning .inner span[data-v-63fbde5e]:first-child{font-weight:700;display:block}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
